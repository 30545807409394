import { isUndefined, isNaN, last } from 'lodash'

function parseVideoURL(url) {
  if (isUndefined(url)) return false
  // eslint-disable-next-line
  const youTubeRE = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/
  // eslint-disable-next-line
  const vimeoRE = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/
  let match

  const error = {
    error: 'Video not found.',
    type: null,
  }

  // Vimeo or Youtube ID
  if (url.length > 6 && url.length < 12) {
    if (!isNaN(url)) {
      return error
    } else if (isNaN(url)) {
      return {
        id: url,
        type: 'youtube',
      }
    }
  }

  // Vimeo URL
  match = url.match(vimeoRE)
  if (match) {
    return {
      id: last(match),
      type: 'vimeo',
    }
  }

  // YouTube URL
  match = url.match(youTubeRE)
  const id = last(match)
  if (id && id.length === 11) {
    return {
      id,
      type: 'youtube',
    }
  }

  return error
}

export default parseVideoURL
